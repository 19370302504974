<script>
export default {
    name: 'Footer'
}
</script>

<template>
    <footer>
        <p>© 2021 Moon Sea</p>
        <p>Final Fantasy XIV © 2010-2021 SQUARE ENIX CO., LTD. All Rights Reserved.<br/>
        This site is not affiliated in any way with SQUARE ENIX CO., LTD</p>
        <p>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a><br/>
        Icons made by <a href="http://www.dariusdan.com" title="Darius Dan">Darius Dan</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
    </footer>
</template>

<style>
    footer {
        width: 100%;
        height: 8vh;
        padding: 1em;
        background: rgba(0, 0, 0, 0.5);
        align-self: flex-end;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
        font-size: 0.75em;
    }
    footer p {
        padding: 0.25em 1em;
    }
    footer p:first-of-type{
        width: 100%;
        text-align: center;
    }
    footer a {
        text-decoration: underline dotted 1px;
    }
</style>