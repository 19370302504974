<script>
export default {
  props: {
    nsfwFilter: { type: String },
  },
  data() {
    return {
      choice: false,
    };
  },
  methods: {
    makeAchoice: function () {
      this.choice = !this.choice;
    },
    emitFilter: function (filter) {
      this.$emit("emit-filter", { filterDegree: filter });
    },
  },
};
</script>

<template>
  <div id="toggleAnchor" @click="makeAchoice">
    <div id="toggleContainer">
      <div id="currentChoice" class="choice">
        <img
          :src="require(`../assets/${this.nsfwFilter}.png`)"
          alt="current choice icon"
        />
        <p>{{ nsfwFilter }}</p>
      </div>
      <div id="choiceContainer" v-if="choice">
        <div id="toggleBar"></div>
        <div class="choice" @click="emitFilter('Pure')">
          <img src="../assets/Pure.png" alt="pure icon" />
          <p>Pure</p>
        </div>
        <div class="choice" @click="emitFilter('Soft')">
          <img src="../assets/Soft.png" alt="soft icon" />
          <p>Soft</p>
        </div>
        <div class="choice" @click="emitFilter('Passion')">
          <img src="../assets/Passion.png" alt="passion icon" />
          <p>Passion</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#toggleAnchor {
  position: relative;
  align-self: flex-end;
  display: flex;
  width: 100%;
}
#toggleAnchor img {
  width: 2em;
  margin-left: 1.25em;
}
#toggleAnchor p {
  width: 100%;
  text-align: center;
}
#toggleContainer {
  position: absolute;
  width: 20em;
  display: flex;
}
#choiceContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 14em;
  margin-left: 1em;
  background-color: rgba(31, 41, 71, 0.75);
  border-radius: 0.5em;
}
#toggleBar {
  position: absolute;
  top: 1.75em;
  left: 2em;
  z-index: 1;
  height: 0.4em;
  width: 10em;
  border-radius: 1em;
  background: linear-gradient(0.25turn, #fafad2, #8b008b);
  box-shadow: inset 0 0 2px 1px rgb(0, 0, 0, 0.5);
}
.choice {
  z-index: 2;
  width: 4.5em;
  padding: 0.5em 0em;
  border-radius: 0.5em;
}
.choice:hover {
  cursor: pointer;
  background-color: rgba(31, 41, 71, 0.75);
  outline: rgba(31, 41, 71, 0.5) auto 1px;
}
</style>
