<script>
export default {
  name: "NsfwWarning",
  data() {
    return {
      step: 0,
    };
  },
  methods: {
    toggleStep(s) {
      if ((this.step >= 1) & (s == 1)) {
        this.step = 0;
      } else {
        this.step = s;
      }
    },
    emitFilter(filter) {
      this.$emit("emit-nsfw", { filterDegree: filter });
    },
  },
};
</script>

<template>
  <div id="warningAnchor">
    <img
      v-if="step == 0"
      src="../assets/Pure.png"
      alt=""
      id="openWarning"
      class="toggleWarning"
      @click="toggleStep(1)"
    />
    <div id="warningContainer" v-if="step > 0">
      <div id="nsfwWarning">
        <img
          v-if="step > 0"
          src="../assets/Pure.png"
          alt=""
          id="closeWarning"
          class="toggleWarning"
          @click="toggleStep(0)"
        />
        <div id="warning" v-if="step == 1">
          <p>
            This website can display unsuitable content for underage You have to
            be at the legal age in your jurisdiction to make Not Safe For Work
            content visible. NSFW include nude and sexual content. After
            validation, you can filter "strong" nsfw.
          </p>
          <button @click="toggleStep(2)">
            Activate "Not Safe For Work" content ?
          </button>
        </div>
        <div id="choiceDegree" v-if="step == 2">
          <p>Soft displays nude content.</p>
          <p>Passion displays sexual content.</p>
          <ul>
            <h4>Choose a filter :</h4>
            <li @click="emitFilter('Soft')">
              <h3>Soft</h3>
              <img src="../assets/Soft.png" alt="Soft filter" />
            </li>
            <li @click="emitFilter('Passion')">
              <h3>Passion</h3>
              <img src="../assets/Passion.png" alt="Passion filter" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#warningAnchor {
  position: relative;
  align-self: flex-end;
  width: 100%;
}
img.toggleWarning {
  position: absolute;
  width: 2.75em;
  padding: 0.3em;
  border-radius: 50%;
  color: lightgoldenrodyellow;
  background-color: cornflowerblue;
  box-shadow: 0 0 5px 1px rgb(31, 41, 71, 1);
}
img.toggleWarning:hover {
  width: 3.25em;
  background-color: darkmagenta;
  box-shadow: 0 0 5px 0 lightgoldenrodyellow;
  cursor: pointer;
}
#openWarning {
  top: 0.25em;
  left: 1em;
}
#openWarning:hover {
  top: 0em;
  left: 0.75em;
}
#closeWarning {
  z-index: 2;
  top: -1.5em;
  right: -1.5em;
}
#closeWarning:hover {
  top: -1.75em;
  right: -1.75em;
}
#warningContainer {
  position: absolute;
  z-index: 1;
  top: -9vh;
  left: 0.25vw;
  width: 99.5vw;
  height: 99.5vh;
  backdrop-filter: blur(0.25em);
  background-color: rgb(0, 0, 0, 0.5);
}
#nsfwWarning {
  position: absolute;
  top: 20vh;
  right: 50vw;
  transform: translate(50%, 0);
  width: 50vw;
  min-width: 300px;
  max-width: 500px;
  padding: 0 3vh;
  padding: 1.5em;
  background-color: rgb(31, 41, 71, 1);
  backdrop-filter: blur(0.25em) brightness(0.75);
  box-shadow: 0 0 5px 0 rgba(72, 88, 116, 0.5);
  border-radius: 0.5em;
  border: ridge 2px darkmagenta;
}
#nsfwWarning button {
  margin-top: 1em;
  margin-left: 50%;
  transform: translate(-50%);
  padding: 0.5em;
  color: darkmagenta;
  font-size: 1.1em;
  background-color: lightgoldenrodyellow;
  border-radius: 0.5em;
  border: none;
  box-shadow: 1px 1px 2px rgb(31, 41, 71, 100%);
}
#nsfwWarning button:hover {
  color: lightgoldenrodyellow;
  background-color: darkmagenta;
  box-shadow: inset 1px 1px 2px rgb(31, 41, 71, 100%);
  cursor: pointer;
}
#choiceDegree ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#choiceDegree ul h4 {
  width: 100%;
  text-align: center;
  margin: 0.75em 0 0.25em;
}
#choiceDegree li {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75em;
  border-radius: 1em;
}
#choiceDegree h3 {
  margin: 0;
  padding: 0;
}
#choiceDegree img {
  height: 3em;
  max-height: 64px;
  margin-top: 0.5em;
}
#choiceDegree li:hover {
  background-color: cornflowerblue;
  cursor: pointer;
}
</style>
