<script>
import MainMenu from "./components/MainMenu";
import NsfwWarning from "./components/NsfwWarning";
import PassionToggle from "./components/PassionToggle";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: { MainMenu, NsfwWarning, PassionToggle, Footer },
  data: function () {
    return {
      nsfwActivation: false,
      nsfwFilter: "Pure",
    };
  },
  methods: {
    setFilter(payload) {
      this.nsfwActivation = true;
      this.nsfwFilter = payload.filterDegree;
      return this.nsfwFilter;
    },
  },
};
</script>

<template>
  <main>
    <MainMenu />
    <NsfwWarning v-if="nsfwActivation == false" @emit-nsfw="setFilter" />
    <PassionToggle
      v-if="nsfwActivation == true"
      v-bind:nsfwFilter="nsfwFilter"
      @emit-filter="setFilter"
    />
    <div id="viewContainer">
      <router-view v-bind:nsfwFilter="nsfwFilter" :key="nsfwFilter" />
    </div>
    <Footer />
  </main>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Merienda&family=Quicksand&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background-image: url("./assets/Background02.png");
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
  background-color: #485874;
}
main {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  font-family: "Quicksand", sans-serif;
  color: LightGoldenrodYellow;
  text-shadow: 2px 2px 2px black;
  background-color: rgba(0, 0, 0, 0.25);
}
#viewContainer {
  height: 82vh;
  overflow: auto;
}
section {
  min-width: 98vw;
  padding: 0 3vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
h1,
h2,
h3 {
  font-family: "Merienda", cursive;
}
h2 {
  margin: 0.5em 0 1em;
  text-align: center;
  width: 100%;
}
h2::before,
h2::after {
  content: " 🎔 ";
}
h3 {
  margin-top: 1em;
}
a {
  color: LightGoldenrodYellow;
  text-emphasis: none;
  text-decoration: none;
}

a:hover {
  color: cornflowerblue;
  text-decoration: cornflowerblue underline 1px;
  text-underline-offset: 0.1em;
  text-shadow: 1px 1px 2px darkmagenta;
}
.cardContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: top;
}
</style>
