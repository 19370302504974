<script>

export default {
    name: 'MainMenu'
}
</script>

<template>
    <menu id="mainMenu">
      <h1>Moon Sea</h1>
      <div id="nav-bar">
        <img src="../assets/moon.png" /><router-link to="/"> Home</router-link>
        <img src="../assets/moon.png" /><router-link to="/poses"> Poses</router-link>
        <img src="../assets/moon.png" /><router-link to="/mods"> Mods</router-link>
        <img src="../assets/moon.png" /><router-link to="/commissions"> Commissions</router-link>
        <img src="../assets/moon.png" /><router-link to="/links"> Links</router-link>
      </div>
    </menu>
</template>

<style>
    #mainMenu {
        width: 100%;
        height: 9vh;
        padding: 0.5em;
        align-self: flex-start;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        background-image: linear-gradient(rgba(31, 41, 71, 1), rgba(31, 41, 71, 0));
    }
    #nav-bar{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    #mainMenu img {
        width: 1.75em;
    }
    #mainMenu h1 {
        font-size : 3em;
        font-weight: normal;
        align-self: start;
    }
    #mainMenu a {
        margin-right: 0.5em;
        padding: 0.25em 0.5em;
    }
    #mainMenu a.isActive {
        border-bottom: 2px solid cornflowerblue;
        border-right: 2px solid cornflowerblue;
        box-shadow: 2px 2px 2px cornflowerblue;
    }
</style>